@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
body {
    /* background-color: #000000 !important; */
    color: #ffff;
    margin: 0;
    font-weight: 300;
    line-height: 1.5em;
    font-family: 'Lato', sans-serif;
}

a {
    color: #000000;
    text-decoration: none;
}
.datetime {
    line-height: 1.1;
}
.time {
    font-size: 25px;
    font-weight: normal;
    opacity: 0.8;
}
.time-suffix {
    font-size: 25px;
    font-weight: lighter;
}
.date {
    font-size: 17px;
    opacity: 0.8;
    font-weight: lighter;
    text-align: center;
}
.mainContainer {
    position: relative;
    top: 60px;
    margin: 0 auto;
    padding-top: 120px;
}
.loadingScreen {
    position: relative;
    width: 100%;
    height: 100%;
    top: 150px;
    margin: 0;
    text-align: center;
}

#content {position:relative;width:400px;height:300px;}
#content:before, #content:after, #content>:first-child:before, #content>:first-child:after {
    position:absolute;
    width:80px; height: 80px;
    border-color:red; /* or whatever colour */
    border-style:solid; /* or whatever style */
    content: ' ';
}
#content:before {top:0;left:0;border-width: 1px 0 0 1px}
#content:after {top:0;right:0;border-width: 1px 1px 0 0}
#content>:first-child:before {bottom:0;right:0;border-width: 0 1px 1px 0}